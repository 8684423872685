import axios from "axios";
import {errorFn, Fn200, preRequestFn, requestErrorFn} from "./errorfunctions";
import UtilityService from "./utility";
let prefecture: any = null;
let retailer: any = null;
let city: any = null;
let area: any = null;
let store: any = null;
let stores: any = [];
let card: any = null;
let favMode: any = false;
let holders: any = [];
let basketpoints = 0;
// Add a request interceptor
axios.interceptors.request.use(preRequestFn, requestErrorFn);

// Add a response interceptor
axios.interceptors.response.use(Fn200, errorFn);



const OCP_API_SUBSCRIPTION_KEY = process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY as string;
const RETAILERS_URL = process.env.REACT_APP_RETAILERS_URL as string;
const STORES_URL = process.env.REACT_APP_STORES_URL as string;
const getRequestHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user-data")!);
    let headers:any = {
        "Ocp-Apim-Subscription-Key": OCP_API_SUBSCRIPTION_KEY,
        "Ocp-Apim-Trace": "true",
        "Content-Type": "application/json",
    };

    if (user && user.access_token) {
            headers['Authorization'] =  `Bearer ${user.access_token}`
            return headers;
    } else {
        if (window.location.pathname == '/1') {

        } else {
            window.location.href = '/1'
        }
    }

};

const getRequestHeadersWithoutAuthentication = () => {
    const user = JSON.parse(localStorage.getItem("user-data")!);
    let headers:any = {
        "Ocp-Apim-Subscription-Key": OCP_API_SUBSCRIPTION_KEY,
        "Ocp-Apim-Trace": "true",
        "Content-Type": "application/json",
    };
    return headers

};

const getUser = () => {
    const user = JSON.parse(localStorage.getItem("user-data")!);
    if (user) {
        return user
    } else {
        if (window.location.pathname == '/1') {

        } else {
            window.location.href = '/1'
        }
    }
};

const setServiceDataFromStorage = () => {
    const data = JSON.parse(window.localStorage.getItem("service-data1") || "{}");
    prefecture = data.prefecture;
    retailer = data.retailer;
    city = data.city;
    area = data.area;
    store = data.store;
    stores = data.stores || [];
    card = data.card;
    favMode = data.favMode || false
    holders = data.holders || [];
    basketpoints = data.basketpoints || 0;
}

setServiceDataFromStorage();
const updateServiceDataToStorage = () => {
    const data = {
        prefecture,
        retailer,
        city,
        area,
        store,
        stores,
        card,
        holders,
        basketpoints,
        favMode
    }
    window.localStorage.setItem("service-data1", JSON.stringify(data));
}

const DataService = {
    getRetailers: () => {
        return axios.get(`${RETAILERS_URL}?per_page=1000`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeaders(),
        })
    },
    getRetailersWithoutAuth: () => {
        return axios.get(`${RETAILERS_URL}?per_page=1000`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeadersWithoutAuthentication(),
        })
    },
    getPrefectures: (retailer: any) => {
        return axios.get(`${RETAILERS_URL}/${retailer.id}/stores/prefectures?per_page=1000`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeaders(),
        })
    },
    getCities: (retailer: any, prefecture: any) => {
        return axios.get(`${RETAILERS_URL}/${retailer.id}/stores/prefectures/cities?prefecture=${prefecture}&per_page=1000`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeaders(),
        })
    },
    getAreas: (retailer: any, prefecture: any, city: any) => {
        return axios.get(`${RETAILERS_URL}/${retailer.id}/stores/prefectures/cities/areas?prefecture=${prefecture}&city=${city}&per_page=1000`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeaders(),
        })
    },
    getStores: (retailer: any, prefecture: any, city: any, area: any) => {
        return axios.get(`${STORES_URL}?retailer_id=${retailer.id}&prefecture=${prefecture}&city=${city}&per_page=1000`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeaders(),
        });
    },
    getStoresCountForRetailer: (retailer: any) => {
        const user = getUser();

        return axios.get(`${STORES_URL}?retailer_id=${retailer.id}&member_id=${user.id}&per_page=10`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeaders(),
        });
    },


    // get stores at every level
    getStoresAtLevelRetailer: (retailer: any) => {
        
        const user = getUser();
        return axios.get(`${STORES_URL}?retailer_id=${retailer.id}&member_id=${user.id}&per_page=100`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeaders(),
        });
    },

    getStoresAtLevelPrefecture: (retailer: any, prefecture: any) => {
        
        const user = getUser();
        return axios.get(`${STORES_URL}?retailer_id=${retailer.id}&member_id=${user.id}&prefecture=${prefecture}&per_page=100`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeaders(),
        });
    },

    getStoresAtLevelCity: (retailer: any, prefecture: any, city: any) => {
        
        const user = getUser();
        return axios.get(`${STORES_URL}?retailer_id=${retailer.id}&member_id=${user.id}&prefecture=${prefecture}&city=${city}&per_page=1000`, {
            timeout: UtilityService.timeOut,
            headers: getRequestHeaders(),
        });
    },
};

export default DataService;
