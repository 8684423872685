import {IonPage, IonSpinner} from "@ionic/react";
import lady from "../screen1/lady.png";

import step1 from "../screen1/step-images/step1_old.png";
import step2 from "../screen1/step-images/step2_old.png";
import step3 from "../screen1/step-images/step3.png";
import step4 from "../screen1/step-images/step4.png";
import backgroundFruits from "./background-fruits.png"

import card1 from "../screen1/card1.png";
import card2 from "../screen1/card2.png";
import card3 from "../screen1/card3.png";

import steptext from "../screen1/steptext.png";
import React, {useEffect, useState} from "react";

import {connect} from "react-redux";
import {
    fetchCategoriesForHomePage,
    fetchOffersForHomePage,
} from "../../../redux/offer-list/offer-list.actions";
import {Link, useHistory, useLocation} from "react-router-dom";

import waon from "../screen2/1.png";
import izumi from "../screen2/2.png";
import iy from "../screen2/10.png";
import yb from "../screen2/6.png";
import {classNames} from "../../components/member-holder-point-retailer/member-holder-point-retailer";
import Marquee from "react-fast-marquee";
import liff from "@line/liff";
import AuthService from "../../services/auth.service";
import MembersService from "../../services/members.service";
import {setLIFF_ID} from "../../../redux/global/global.actions";

const steps = [
    {
        heading: "Step 1",
        subtitle: "メールアドレスとカードを登録する",
        image: step1,
    },
    {
        heading: "Step 2",
        subtitle: "よく行くお店を登録する",
        image: step2,
    },
    {
        heading: "Step 3",
        subtitle: "使いたいクーポンをセットすることで登録カードと紐付けをおこないます",
        image: step3,
    },
    {
        heading: "Step 4",
        subtitle: "商品を購入する際はレジで登録カードを提示すればポイント付与",
        image: step4,
    },
];
const mapStateToProps = (state: any) => {
    return {
        state: state,
        offers: state.offerList.offers,
        first6offers: state.offerList.first6offers,
        LIFF_ID: state.global.LIFF_ID,
        isSocialAuthenticated: state.global.isSocialAuthenticated
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchOffers: (catId: string) => dispatch(fetchOffersForHomePage(catId)),
        fetchCategories: () => dispatch(fetchCategoriesForHomePage()),
        setLiffId: (LIFF_ID: string) => dispatch(setLIFF_ID(LIFF_ID))
    };
};

interface Props {
    state: any;
    offers: any[];
    first6offers: any[];
    fetchOffers: Function;
    fetchCategories: Function;
    LIFF_ID: any;
    isSocialAuthenticated: any;
    setLiffId: Function;
}

function useQueryParams() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}


export const CouponPlusInfoComponent = (props: Props) => {
    let waonId = process.env.REACT_APP_WAON_ID as string;
    let izumiId = process.env.REACT_APP_IZUMI_ID as string;
    let iyId = process.env.REACT_APP_IY_ID as string;
    let ybId = process.env.REACT_APP_YB_ID as string;
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
    };
    let imageURL: any = {};
    imageURL[waonId] = waon;
    imageURL[izumiId] = izumi;
    imageURL[iyId] = iy;
    imageURL[ybId] = yb;

    let queryParams = useQueryParams();
    const history = useHistory();
    const [errorMessage,] = useState<string>("");
    const [openErrorModel,] = useState<boolean>(false);
    const [isLoading, setLoading] = useState(false);
    let todayDate = new Date();
    let debug  = queryParams.get('debug');

    const bannerStart = (debug === 'true')? new Date('2024/02/28 0:0:0') : new Date(process.env.REACT_APP_BANNER_START_JULY);
    const bannerEnd = new Date(process.env.REACT_APP_BANNER_END_JULY);


    let app_retailer_id = queryParams.get('retailerId');
    let id_filter: any = [];
    let retailers = [waonId, izumiId, iyId, ybId]

    if (app_retailer_id) {
        id_filter = app_retailer_id.split(',');
        localStorage.setItem('app_retailer_id', queryParams.get('retailerId'))
    }
    if (id_filter.length > 0) {
        retailers = retailers.filter((retailer) => id_filter.includes(retailer))
    }


    const getLiffId = () => {
        let LIFF_ID = process.env.REACT_APP_LIFF_ID_ALL as string
        if (queryParams.get('campaign')) {
            if (!queryParams.get('retailerId')) {
                // console.log(queryParams.get('retailers'))
                LIFF_ID = process.env.REACT_APP_LIFF_ID_ALL_CAMPAIGN as string
                // localStorage.setItem('app_retailer_id', queryParams.get('retailerId'))
            } else if (queryParams.get('retailerId') === iyId) {
                LIFF_ID = process.env.REACT_APP_LIFF_ID_IY_CAMPAIGN as string
            } else if (queryParams.get('retailerId') === ybId) {
                LIFF_ID = process.env.REACT_APP_LIFF_ID_YB_CAMPAIGN as string
            }
        } else {
            if (!queryParams.get('retailerId')) {
                // console.log(queryParams.get('retailers'))
                LIFF_ID = process.env.REACT_APP_LIFF_ID_ALL as string
                // localStorage.setItem('app_retailer_id', queryParams.get('retailerId'))
            } else if (queryParams.get('retailerId') === iyId) {
                LIFF_ID = process.env.REACT_APP_LIFF_ID_IY as string
            } else if (queryParams.get('retailerId') === ybId) {
                LIFF_ID = process.env.REACT_APP_LIFF_ID_YB as string
            } else if (queryParams.get('retailerId') === izumiId) {
                LIFF_ID = process.env.REACT_APP_LIFF_ID_YouMe as string
            } else if (queryParams.get('retailerId') === waonId) {
                LIFF_ID = process.env.REACT_APP_LIFF_ID_WAON as string
            }
        }
        return LIFF_ID;
    }

    useEffect(() => {
        let cacheCleaned = JSON.parse(localStorage.getItem('cache-cleaned'));
        let starStore = JSON.parse(localStorage.getItem('fav-store'));
        let isHomePopupShown = JSON.parse(localStorage.getItem('isHomePopupShown'));

        if (!cacheCleaned) {
            localStorage.clear();
        }
        localStorage.setItem('cache-cleaned', 'true')
        localStorage.setItem("isHomePopupShown", isHomePopupShown);
        if (starStore && !queryParams.get('retailerId')) {
            localStorage.setItem('fav-store', JSON.stringify(starStore))
        }
        props.fetchOffers(1);
        props.fetchCategories();
        console.log(props.isSocialAuthenticated)
        if (!props.isSocialAuthenticated) {
            sendMessage();
        }
        return () => {
            setLoading(false);
        };
    }, []);

    const sendMessage = async () => {
        setLoading(true);
        let LIFF_ID = props.LIFF_ID;
        if (!props.LIFF_ID) {
            LIFF_ID = getLiffId()
            props.setLiffId(LIFF_ID);
        }


        liff
            .init({ liffId: LIFF_ID })
            .then(async () => {
                // console.log(liff.isLoggedIn());
                if (liff.isLoggedIn()) {
                    let line_email = (await liff.getDecodedIDToken())
                        ? (await liff.getDecodedIDToken()).email
                        : "NA"
                    localStorage.setItem("user-liff-data", JSON.stringify((await liff.getProfile())));
                    localStorage.setItem("user-liff-email", line_email);
                    await handleLogin((await liff.getProfile()).userId);
                } else {
                    liff.login();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleLogin = async (userId: any) => {
        setLoading(true);
        AuthService.socialLogin(liff.getAccessToken(), userId)
            .then((response) => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "virtualPageview",
                    pageTitle: "login",
                    pageUrl: window.location.pathname, // replace page url with current url
                    dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
                });
                localStorage.setItem("user-data", JSON.stringify(response.data));
                let p1 = MembersService.getHolders()
                let p2 = MembersService.getMember();
                Promise.all([p1, p2]).then(response => {
                    // console.log('Execcuting two asyncsss')
                    // console.log(response)
                    let holderResponse = response[0]
                    let memberProfileResponse = response[1]
                    localStorage.setItem("user-card-data", JSON.stringify(holderResponse.data));
                    localStorage.setItem("user-profile", JSON.stringify(memberProfileResponse.data));
                })
                return MembersService.getFavoriteStores();
            })
            .then((response) => {
                if (response.status === 200) {
                    history.replace("/tabs/home");
                } else {
                    history.replace("1");
                }
            })
            .catch((err) => {
                // Local storage issue testing
                if (err.response.status === 404) {
                    localStorage.removeItem("user-data");
                    setLoading(false);
                    console.log(err);
                }
            });
    };
    const goToCampaign = () => {
        let userLiffData = JSON.parse(localStorage.getItem('user-liff-data'));
        window.dataLayer = window.dataLayer || [];
        console.log({
            event: "virtualPageview",
            pageTitle: "login",
            campaignButtonId: "VisitCampaignButton",
            dm_user_id: userLiffData?userLiffData.userId:0,
            pageUrl: window.location.pathname, // replace page url with current url
            dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
        });
        window.dataLayer.push({
            event: "virtualPageview",
            pageTitle: "login",
            campaignButtonId: "VisitCampaignButton",
            dm_user_id: userLiffData?userLiffData.userId:0,
            pageUrl: window.location.pathname, // replace page url with current url
            dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
        });
        let app_retailer_id = localStorage.getItem("app_retailer_id");
        let url = "/0"
        if(app_retailer_id){
            url = url + '?retailerId='+app_retailer_id
        }
        history.push(url);
    }

    return (
        <IonPage>
            {isLoading && (
                <div className="flex justify-center pt-24">
                    <div className="loader">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                </div>
            )}
            {!isLoading && (
               <>
                   <div className="flex flex-col flex-1 overflow-auto">

                       {/*<br/>*/}
                       <div className="block mt-6">
                           {/*<div className="text-2xl font-bold text-center">いつものお買い物で</div>*/}
                           {/*<div className="text-2xl tracking-tighter text-center font-extrabold">ポイントゲット</div>*/}
                           <div className="text-xl font-bold text-center">いつものお買い物で</div>
                           <div className="text-3xl tracking-tighter text-center font-extrabold"
                                style={{color: "#FF592A"}}>ポイントゲット
                           </div>
                           <div className="">
                               <div className="flex items-end justify-center mt-4">
                                   <img className="" style={{width: "40%"}} src={lady}/>
                               </div>


                               <Link to="/sign-up/registration">
                                   <div style={{
                                       backgroundColor: "#F23A00"
                                   }} className="rounded-md font-bold text-white text-center p-4 mx-3 mt-0 mb-2">会員登録をする
                                   </div>
                               </Link>

                           </div>
                           {(todayDate > bannerStart && todayDate < bannerEnd) && localStorage.getItem('app_retailer_id') !== ybId &&
                               <div
                                   className="flex flex-col items-center justify-start flex-1 overflow-auto details p-4">
                                   <img className=''
                                        src="https://res.cloudinary.com/cwjp/image/upload/v1720485946/LCM_CAMPAIGN/LCP_Campaign_button_July_kkbrso.png"
                                        onClick={goToCampaign}
                                   />

                               </div>
                           }
                           <div className="p-6 text-lg">使えるお店</div>
                           <div className="flex items-end">
                               {localStorage.getItem('app_retailer_id') === ybId ?
                                   <img className="ml-8" style={{width: "50%"}}
                                        src={"https://res.cloudinary.com/cwjp/image/upload/v1720058467/LCM/york_p8qwmq.jpg"}/>
                                   : <img className="" style={{width: "100%"}}
                                          src={"https://res.cloudinary.com/cwjp/image/upload/v1693885077/LCM/retailer_logos_wcaiqc.png"}/>}

                           </div>

                           {queryParams.get('retailerId') === ybId ? <div className="p-6">
                               ・ヨークベニマル
                           </div> : <div className="p-6">
                               ・イオン、マックスバリュ、ダイエーなど
                               電子マネーWAONが使える全国のスーパーマーケット <br/>
                               ・イトーヨーカドー<br/>・ヨークベニマル<br/>・ゆめタウン、ゆめマート
                           </div>}

                           <div className="px-6 text-gray-400">
                               ※対象外の企業・店舗がございます。
                           </div>

                           {/*<br />*/}

                           <div className="flex px-6 items-start justify-between" style={{marginTop: "1.25rem"}}>
                               <div className="text">使えるカード</div>
                           </div>

                           {id_filter.length > 0 && app_retailer_id === izumiId &&
                               <div className="grid grid-cols-3 gap-2 p-6">
                                   {retailers.map((card: any) => (
                                       <div className="">
                                           <img src={imageURL[card]}/>
                                           <div className="text-xs py-2 text-gray-800">ゆめカード</div>
                                       </div>
                                   ))}
                               </div>}
                           {id_filter.length > 0 && app_retailer_id === waonId &&
                               <div className="grid grid-cols-3 gap-2 p-6">
                                   {retailers.map((card: any) => (
                                       <div className="">
                                           <img src={imageURL[card]}/>
                                           <div className="text-xs py-2 text-gray-800">電子マネーWAON</div>
                                       </div>
                                   ))}
                               </div>}
                           {id_filter.length > 0 && (app_retailer_id === iyId || app_retailer_id === ybId) &&
                               <div className="grid grid-cols-3 gap-2 p-6">
                                   {retailers.map((card: any) => (
                                       <div className="">
                                           <img src={imageURL[card]}/>
                                           <div className="text-xs py-2 text-gray-800">電子マネーnanaco</div>
                                       </div>
                                   ))}
                               </div>}
                           {id_filter.length === 0 && <div className="grid grid-cols-3 gap-2 p-6 pt-0 pb-1">
                               <div className="">
                                   <img src={card1}/>
                                   <div className="text-sm py-2 text-gray-800">電子マネーWAON</div>
                               </div>
                               <div className="">
                                   <img src={card2}/>
                                   <div className="text-sm py-2 text-gray-800">電子マネーnanaco</div>
                               </div>
                               <div className="">
                                   <img src={card3}/>
                                   <div className="text-sm py-2 text-gray-800">ゆめカード</div>
                               </div>
                           </div>}


                           <div className="pb-6 pl-6 pr-6" style={{background: "#FFFFFF"}}>
                               <div className="text-sm text-gray-400 ">
                                   ※電子マネー一体型のクレジットカードも対象です。
                               </div>
                           </div>
                       </div>

                       <div className="p-6" style={{background: "#FFFFFF"}}>
                           <div className="text-lg mb-1813">ポイントを貯める方法</div>
                           <div className="coupons">
                               {steps.map((step, index) => (
                                   <div key={step.heading}>
                                       <div className="text-catalinared">{step.heading}</div>
                                       <div className="mt-1">{step.subtitle}</div>
                                       <img className={classNames(
                                           "object-contain mx-auto my-10",
                                           index === 0 ? "h-16" : "",
                                           index === 1 ? "h-20" : "",
                                           index === 2 ? "h-28" : "",
                                           index === 3 ? "h-24" : "",
                                       )} src={step.image}/>
                                   </div>
                               ))}

                               <div>※ 登録しているカードを購入時に必ずご提示ください。</div>
                               <div>※ 決済機能が付いたカードの場合、そのカードによる決済が必要となります。</div>
                           </div>

                       </div>

                       <Link to="/sign-up/registration">
                           <div className="bg-catalinared rounded-md font-bold text-white text-center p-4 mx-4 mt-6 mb-2">会員登録をする</div>
                       </Link>

                       <br /> <br />

                       <div
                           style={{background: "rgb(71 71 71)"}}
                           className="px-6 pb-10 pt-14"
                       >
                           <img src={steptext} className="h-5"/>
                           <div className="text-white text-xs mt-1">LINEクーポン×カタリナ</div>
                           <div className="mt-4" style={{fontSize: 10, color: "#B7B7B7"}}>
                               ©LINE Corporation
                           </div>
                       </div>
                   </div>
                   {openErrorModel && (
                       <div className="text-red-600 text-xs -mt-3 mb-10">{errorMessage}</div>
                   )}
               </>
            )}

        </IonPage>
    );
};
export const CouponPlusInfoUpdated = connect(
    mapStateToProps,
    mapDispatchToProps
)(CouponPlusInfoComponent);
